<template>
    <div>
<div class="loading" v-if="loader">
    <div class="contented" style="margin-left: -102px;margin-top: -133px;">
        <div class="circle"></div>
        <div class="circle"></div>
        <!-- <div class="circle"></div>
        <div class="circle"></div> -->
    </div>
</div>
<div class="content-header">

    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0">Category List</h1>
            </div><!-- /.col -->
            <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                    <li class="breadcrumb-item"><a href="#">Home</a></li>
                    <li class="breadcrumb-item active">Category List</li>
                </ol>
            </div><!-- /.col -->
        </div><!-- /.row -->
    </div><!-- /.container-fluid -->
</div>
<!-- Main content -->
<section class="content">
    <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->

        <!-- /.card -->
        <div class="card row">
            <div class="card-header">
                <h3 class="card-title">Category List</h3>

                <div class="card-tools">
                    <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add Category</button>
                    <!-- <button type="button" class="btn btn-tool" data-card-widget="collapse">
               <i class="fas fa-minus"></i>
             </button> -->

                </div>
            </div>
            <!-- /.card-header -->
            <div class="">
                <table id="tableComponent" class="table table-bordered table-striped text-center">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>category Name</th>
                            <th>Image</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(slide,index) in fetchCategory" :key="index">
                            <td>{{index+1}}</td>
                            <td>{{slide.name}}</td>
                            <td class="text-center">
                                <img :src="getImage1(slide.img)" class="img-fluid w-100 peinture-ombre-interne-fine" alt="..." style="height:100px;max-width: 100%;">

                            </td>
                            <td>
                                <p v-if="slide.status === 'Hide'">False</p>
                                <p v-if="slide.status != 'Hide'">True</p>
                            </td>
                            <td class="text-center">
                                <button type="button" data-toggle="modal" data-target="#modal-default" @click="editcategory(slide.id,slide.img,slide.name,slide.status,slide.t1,slide.t2,slide.t3,slide.t4,slide.t5)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button>
                                <button type="button" @click="deletecategs(slide.id)" style="border: none;color: #28a745;background: none;"><i class="fa fa-trash fa-2x" aria-hidden="true"></i></button>
                            </td>
                        </tr>

                    </tbody>

                </table>

            </div>
            <!-- /.row -->

            <!-- /.card-body -->
            <div class="card-footer">

            </div>
        </div>
    </div>
</section>
<div class="modal fade" id="modal-default-addDepart">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Add Category</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form v-on:submit.prevent="submitForm" ref="textareaform" enctype="multipart/form-data">

                    <div class="card-body">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Category Name</label>
                            <input type="text" v-model="catename" class="form-control" id="details" placeholder="Enter details">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Image</label>
                            <image-uploader
                                :preview="true"
                                :maxWidth="512"
                                :maxHeight="512"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                                :capture="false"
                                :debug="1"
                                doNotResize="gif"
                                :autoRotate="true"
                                outputFormat="verbose"
                                @input="setImage"
                                > </image-uploader>
                            </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(8 Am To 11 Am)</label>
                            <select id="t1" class="form-control" v-model="t1">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="t2">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 3 Pm)</label>
                            <select id="t1" class="form-control" v-model="t3">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(7 pm To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="t4">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Extra</label>
                            <select id="t1" class="form-control" v-model="t5">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Status</label>
                            <select v-model="status" class="form-control" id="status" required>
                                <option value="true">True</option>
                                <option value="Hide">false</option>
                            </select>
                            <!-- <input type="text" v-model="status"  class="form-control" id="status" placeholder="Enter status"> -->
                        </div>
                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>

                </form>
            </div>
            <div class="modal-footer justify-content-between">
                <button type="button" id="close" class="btn btn-default" data-dismiss="modal" @click="close">Close</button>

            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>

<div class="modal fade" id="modal-default" @close="showModal = false">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Edit Banner</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form @submit.prevent="updatedcategory" enctype="multipart/form-data">

                    <div class="card-body">
                        <img :src="Editpreview" width="100" height="100" alt="" srcset="">
                        <div class="form-group">
                            <label for="exampleInputEmail1">Image</label>
                            <image-uploader
                                :preview="true"
                                :maxWidth="512"
                                :maxHeight="512"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                                :capture="false"
                                :debug="1"
                                doNotResize="gif"
                                :autoRotate="true"
                                outputFormat="verbose"
                                @input="setImage2"
                                > </image-uploader>
                        </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">Category Name</label>
                            <input type="text" v-model="editcatename" class="form-control" id="details" placeholder="Enter details">
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(8 Am To 11 Am)</label>
                            <select id="t1" class="form-control" v-model="editt1">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="editt2">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 3 Pm)</label>
                            <select id="t1" class="form-control" v-model="editt3">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(7 pm To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="editt4">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Extra</label>
                            <select id="t1" class="form-control" v-model="editt5">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Status</label>
                            <select v-model="editstatus" class="form-control" id="editstatus">
                                <option value="true">True</option>
                                <option value="Hide">false</option>
                            </select>
                            <!-- <input type="text" v-model="editstatus"  class="form-control" id="status" placeholder="Enter status"> -->
                        </div>

                    </div>
                    <!-- /.card-body -->

                    <div class="card-footer">
                        <button type="submit" class="btn btn-primary">Submit</button>
                    </div>

                </form>
            </div>
            <div class="modal-footer justify-content-between">

                <button type="button" id="close1" class="btn btn-default" data-dismiss="modal" @click="close">Close</button>

            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
</div>
</template>

<script>
import {
    mapGetters
} from "vuex"
import ImageUploader from "../components/ImageUploader.vue";
import Swal from 'sweetalert2'

export default {
    name: 'hello-world',
    components: {
    ImageUploader,
  },
    data() {
        return {

            status: '',
            image: '',
            editimage: '',
            base64: '',
            catename: '',
            editcatename: '',
            editstatus: '',
            Editpreview: '',
            Editcateid: '',
            previ: '',
            loader: false,
            previewImage: '',
            t1: '',
            t2: '',
            t3: '',
            t4: '',
            t5: '',
            editt1: '',
            editt2: '',
            editt3: '',
            editt4: '',
            editt5: '',
            hasImage: false,
            mainimg:'',
            mainimg2:''
        }
    },
    computed: {
        ...mapGetters(['fetchCategory'])
    },
    mounted() {
        this.fetchCateg()
    },
    methods: {
        setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log(output);

      let image_file = this.urlToFile(output.dataUrl);
      //this.submitdt(image_file);
      this.mainimg=image_file;
    },
    setImage2: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log(output);

      let image_file = this.urlToFile(output.dataUrl);
      //this.submitdt(image_file);
      this.mainimg2=image_file;
    },
    urlToFile(url) {
      let arr = url.split(",");
      console.log(arr);
      let mime = arr[0].match(/:(.*?);/)[1];
      let data = arr[1];

      let dataStr = atob(data);
      let n = dataStr.length;
      let dataArr = new Uint8Array(n);

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n);
      }

      let file = new File([dataArr], "File.jpg", { type: mime });

      return file;
    },
        async fetchCateg() {
            this.loader = true;
            await this.$store.dispatch('CategoryList')
            this.loader = false;
            //fetchDepart

        },
        deletecategs(id) {
            Swal.fire({
                title: 'Do you want to Delete this Banner?',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Delete',
                denyButtonText: `Don't Delete`,
            }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    this.$store.dispatch('Deletecategory', id);

                    Swal.fire('Delete!', '', 'success')
                } else if (result.isDenied) {
                    Swal.fire('Changes are not saved', '', 'info')
                }
            })
        },
        selectImage() {
            this.$refs.fileInput.click()
        },
        getImage1(imagePath) {
            return 'https://wdlapi.areteonline.in/Img/cat/' + imagePath;
        },
        close() {
            this.$emit('close');
        },
        async submitForm() {
            this.loader = true;

            let payload = {
                img: this.mainimg,
                status: this.status,
                name: this.catename,
                t1:this.t1,
                t2:this.t2,
                t3:this.t3,
                t4:this.t4,
                t5:this.t5,
            }

            await this.$store.dispatch('addCategory', payload);
            this.loader = false;

            // let payload={
            //     image:this.img,
            //     status:this.status,
            //     details:this.details
            // }
            // this.tableData= this.$store.dispatch('AddBanner',payload);

            this.status = '';
            this.catename = '';
            this.image = '';
            document.getElementById('close').click();

            this.close();
            document.getElementById('close').click();

        },
        async editcategory(id,img,name,status,t1,t2,t3,t4,t5){
            this.Editcateid=id;
          this.Editimage='';
          this.Editpreview=this.getImage1(img);
          this.editcatename=name;
          this.editstatus=status;
          this.previ=img;
          this.editt1=t1;
          this.editt2=t2;
          this.editt3=t3;
          this.editt4=t4;
          this.editt5=t5;
        },
        async updatedcategory(){
            this.loader = true;

            let payload = {
                id:this.Editcateid,
                img: this.mainimg2?this.mainimg2:this.previ,
                status: this.editstatus,
                name: this.editcatename,
                t1:this.editt1,
                t2:this.editt2,
                t3:this.editt3,
                t4:this.editt4,
                t5:this.editt5,
                prio:'1'
            }

            this.$store.dispatch('UpdateCategoryDetails',payload);

            this.loader = false;

            Swal.fire({
            text: 'Successfully Update record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

        document.getElementById('close1').click();

        this.close();
        document.getElementById('close').click();



        }

    }

}
</script>

<template>
  <div>

    <div class="loading" v-if="loader">
     <div class="contented" style="margin-left: -102px;margin-top: -133px;">
       <div class="circle"></div>
       <div class="circle"></div>
       <!-- <div class="circle"></div>
       <div class="circle"></div> -->
     </div>
 </div>
   <div class="content-header">
     <div class="container-fluid">
       <div class="row mb-2">
         <div class="col-sm-6">
           <h1 class="m-0">Items List</h1>
         </div><!-- /.col -->
         <div class="col-sm-6">
           <ol class="breadcrumb float-sm-right">
             <li class="breadcrumb-item"><a href="#">Home</a></li>
             <li class="breadcrumb-item active">Items List</li>
           </ol>
         </div><!-- /.col -->
       </div><!-- /.row -->
     </div><!-- /.container-fluid -->
   </div>
   <!-- Main content -->
   <section class="content">
     <div class="container-fluid">
       <!-- SELECT2 EXAMPLE -->
    
       <!-- /.card -->
       <div class="card row" style="overflow: scroll;">
         <div class="card-header">
           <h3 class="card-title">Items List</h3>

           <div class="card-tools">
             <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add Item</button>
             <!-- <button type="button" class="btn btn-tool" data-card-widget="collapse">
               <i class="fas fa-minus"></i>
             </button> -->
           
           </div>
         </div>
         <!-- /.card-header -->
           <div class="">
             <table id="tableComponent" class="table table-bordered table-striped text-center">
                 <thead>
                 <tr>
                   <th>ID</th>
                   <th>Category Name</th>
                   <th>Item Name</th>
                   <th>Description</th>
                   <th>Price</th>
                   <th>Image</th>
                   <th>Action</th>
                 </tr>
                 </thead>
                 <tbody>
                   <tr v-for="(slide,index) in fetchItem" :key="index">
                       <td style="width:100px">{{index+1}}</td>
                       <td style="width:100px">{{slide.cateName}}</td>
                       <td style="width:100px">{{slide.itemName}}</td>
                       <td style="width:100px">{{slide.desc}}</td>
                       <td style="width:100px">{{slide.price}}</td>
                       <td class="text-center" style="width:100px">
                           <img :src="getImage1(slide.img)"  class="img-fluid w-100 peinture-ombre-interne-fine" alt="..."  style="height:100px;max-width: 100%;">

                       </td>
                       <td style="width:100px">
                           <p v-if="slide.status === 'Hide'">False</p>
                           <p v-if="slide.status != 'Hide'">True</p>
                       </td>
                       <td class="text-center" style="width:100px">
                           <button type="button"  data-toggle="modal" data-target="#modal-default" @click="editItem(slide.id,slide.cateName,slide.itemName,slide.desc,slide.img,slide.status,slide.price)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button>
                           <button type="button"  @click="deleteItems(slide.id)" style="border: none;color: #28a745;background: none;"><i class="fa fa-trash fa-2x" aria-hidden="true"></i></button>
                       </td>
                   </tr>

                 </tbody>
               
               </table>
            
           </div>
           <!-- /.row -->

         <!-- /.card-body -->
         <div class="card-footer">
         
         </div>
       </div>
    </div>
   </section> 
   <div class="modal fade" id="modal-default-addDepart">
       <div class="modal-dialog">
         <div class="modal-content">
           <div class="modal-header">
             <h4 class="modal-title">Add Item</h4>
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
           <div class="modal-body">
             <form v-on:submit.prevent="submitForm"  ref="textareaform" enctype="multipart/form-data">

               <div class="card-body">
                <div class="form-group">
                   <label for="exampleInputEmail1">Category Name</label>
                   <select v-model="categName"  class="form-control" id="status" required>
                       <option v-for="val in fetchCategory" :key="val.id" :value="val.name">{{val.name}}</option>
                   </select>
                   <!-- <input type="text" v-model="status"  class="form-control" id="status" placeholder="Enter status"> -->
                 </div>
                 <div class="form-group">
                   <label for="exampleInputEmail1">Item Name</label>
                   <input type="text" v-model="itemname"  class="form-control" id="Item" placeholder="Enter Item">
                 </div>
                 <div class="form-group">
                   <label for="exampleInputEmail1">Description</label>
                   <input type="text" v-model="description"  class="form-control" id="description" placeholder="Enter description">
                 </div>

                 <div class="form-group">
                   <label for="exampleInputEmail1">Price</label>
                   <input type="number" v-model="price"  class="form-control" id="price" placeholder="Enter price">
                 </div>

                 <div class="form-group">
                            <label for="exampleInputEmail1">Image</label>
                            <image-uploader
                                :preview="true"
                                :maxWidth="512"
                                :maxHeight="512"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                                :capture="false"
                                :debug="1"
                                doNotResize="gif"
                                :autoRotate="true"
                                outputFormat="verbose"
                                @input="setImage"
                                > </image-uploader>
                        </div>
                <div class="form-group">
                            <label for="exampleInputEmail1">Time(8 Am To 11 Am)</label>
                            <select id="t1" class="form-control" v-model="t1">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="t2">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 3 Pm)</label>
                            <select id="t1" class="form-control" v-model="t3">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(7 pm To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="t4">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Extra</label>
                            <select id="t1" class="form-control" v-model="t5">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">Volume</label>
                        <input type="text" v-model="Volume"  class="form-control" id="Volume" placeholder="Enter Volume">
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">Priority</label>
                        <input type="number" v-model="Priority"  class="form-control" id="Priority" placeholder="Enter Priority">
                        </div>

             
                        <div class="form-group">
                            <label for="exampleInputEmail1">Status</label>
                            <select v-model="status" class="form-control" id="status" required>
                                <option value="true">True</option>
                                <option value="Hide">false</option>
                            </select>
                            <!-- <input type="text" v-model="status"  class="form-control" id="status" placeholder="Enter status"> -->
                        </div>
                 
               </div>
               <!-- /.card-body -->

               <div class="card-footer">
                 <button type="submit" class="btn btn-primary">Submit</button>
               </div>

             </form>
           </div>
           <div class="modal-footer justify-content-between">
             <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

           </div>
         </div>
         <!-- /.modal-content -->
       </div>
       <!-- /.modal-dialog -->
   </div>

   <div class="modal fade" id="modal-default" @close="showModal = false">
       <div class="modal-dialog">
         <div class="modal-content">
           <div class="modal-header">
             <h4 class="modal-title">Edit Items</h4>
             <button type="button" class="close" data-dismiss="modal" aria-label="Close">
               <span aria-hidden="true">&times;</span>
             </button>
           </div>
           <div class="modal-body">
             <form @submit.prevent="editItems" enctype="multipart/form-data">

               <div class="card-body">
                <img :src="Editpreview" width="100" height="100" alt="" srcset="">

                <div class="form-group">
                   <label for="exampleInputEmail1">Category Name</label>
                   <select v-model="editcategName"  class="form-control" id="status" required>
                       <option v-for="val in fetchCategory" :key="val.id" :value="val.name">{{val.name}}</option>
                   </select>
                   <!-- <input type="text" v-model="status"  class="form-control" id="status" placeholder="Enter status"> -->
                 </div>
                 <div class="form-group">
                   <label for="exampleInputEmail1">Item Name</label>
                   <input type="text" v-model="edititemname"  class="form-control" id="Item" placeholder="Enter Item">
                 </div>
                 <div class="form-group">
                   <label for="exampleInputEmail1">Description</label>
                   <input type="text" v-model="editdescription"  class="form-control" id="description" placeholder="Enter description">
                 </div>

                 <div class="form-group">
                   <label for="exampleInputEmail1">Price</label>
                   <input type="number" v-model="editprice"  class="form-control" id="price" placeholder="Enter price">
                 </div>

                        <div class="form-group">
                            <label for="exampleInputEmail1">Image</label>
                            <image-uploader
                                :preview="true"
                                :maxWidth="512"
                                :maxHeight="512"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                                :capture="false"
                                :debug="1"
                                doNotResize="gif"
                                :autoRotate="true"
                                outputFormat="verbose"
                                @input="setImage2"
                                > </image-uploader>
                        </div>
                   
                    <div class="form-group">
                            <label for="exampleInputEmail1">Time(8 Am To 11 Am)</label>
                            <select id="t1" class="form-control" v-model="editt1">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="editt2">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(11 Am To 3 Pm)</label>
                            <select id="t1" class="form-control" v-model="editt3">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Time(7 pm To 11 Pm)</label>
                            <select id="t1" class="form-control" v-model="editt4">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="exampleInputEmail1">Extra</label>
                            <select id="t1" class="form-control" v-model="editt5">
                                <option value="Available">Available</option>
                                <option value="Not-Available">Not-Available</option>
                            </select>
                        </div>
                        <div class="form-group">
                        <label for="exampleInputEmail1">Volume</label>
                        <input type="text" v-model="editVolume"  class="form-control" id="Volume" placeholder="Enter Volume">
                        </div>

                        <div class="form-group">
                        <label for="exampleInputEmail1">Priority</label>
                        <input type="number" v-model="editPriority"  class="form-control" id="Priority" placeholder="Enter Priority">
                        </div>

             

                 <div class="form-group">
                   <label for="exampleInputEmail1">Status</label>
                   <select v-model="editstatus" class="form-control" id="editstatus">
                       <option value="true">True</option>
                       <option value="Hide">false</option>
                   </select>
                   <!-- <input type="text" v-model="editstatus"  class="form-control" id="status" placeholder="Enter status"> -->
                 </div>
                 
             
               </div>
               <!-- /.card-body -->

               <div class="card-footer">
                 <button type="submit" class="btn btn-primary">Submit</button>
               </div>

             </form>
           </div>
           <div class="modal-footer justify-content-between">
            
             <button type="button" id="close1" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

           </div>
         </div>
         <!-- /.modal-content -->
       </div>
       <!-- /.modal-dialog -->
   </div>
     <!-- /.modal --> 
  </div>

</template>

<script>
import { mapGetters } from "vuex"
import ImageUploader from "../components/ImageUploader.vue";
import Swal from 'sweetalert2'

 export default {
   name:'hello-world',
   components: {
    ImageUploader,
  },
     data() {
         return {
           status:'',
           image:'',
           editimage:'',
           base64:'',
           details:'',
           editdetails:'',
           editstatus:'',
           Editpreview:'',
           EditItemsid:'',
           previ:'',
           loader:false,
           Priority:'',
           Volume:'',
           editPriority:'',
           editVolume:'',
           description:'',
           editdescription:'',
           price:'',
           editprice:'',
           t1: '',
            t2: '',
            t3: '',
            t4: '',
            t5: '',
            editt1: '',
            editt2: '',
            editt3: '',
            editt4: '',
            editt5: '',
            categName:'',
            itemname:'',
            editcategName:'',
            edititemname:'',
            previewImage:'',
            hasImage: false,
            mainimg:'',
            mainimg2:''
         }
     },
     computed: {
   ...mapGetters(['fetchItem','fetchCategory'])
     },
     mounted() {
       this.fetchDeprt()
     },
     methods: {
      setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log(output);

      let image_file = this.urlToFile(output.dataUrl);
      //this.submitdt(image_file);
      this.mainimg=image_file;
    },
    setImage2: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log(output);

      let image_file = this.urlToFile(output.dataUrl);
      //this.submitdt(image_file);
      this.mainimg2=image_file;
    },
    urlToFile(url) {
      let arr = url.split(",");
      console.log(arr);
      let mime = arr[0].match(/:(.*?);/)[1];
      let data = arr[1];

      let dataStr = atob(data);
      let n = dataStr.length;
      let dataArr = new Uint8Array(n);

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n);
      }

      let file = new File([dataArr], "File.jpg", { type: mime });

      return file;
    },
        deleteItems(id){
            Swal.fire({
           title: 'Do you want to Delete this Banner?',
           showDenyButton: true,
           showCancelButton: true,
           confirmButtonText: 'Delete',
           denyButtonText: `Don't Delete`,
           }).then((result) => {
           /* Read more about isConfirmed, isDenied below */
           if (result.isConfirmed) {
                   this.$store.dispatch('DeleteItems',id);

                 Swal.fire('Delete!', '', 'success')
           } else if (result.isDenied) {
                 Swal.fire('Changes are not saved', '', 'info')
           }
           })
       },
       selectImage () {
         this.$refs.fileInput.click()
     },
       getImage1(imagePath) {
         return 'https://wdlapi.areteonline.in/Img/item/'+imagePath;
     },
       close() {
       this.$emit('close');
     },
     async  editItem(id,cateName,itemName,desc,img,status,price)
       {
       // await this.$store.dispatch('getRecordDeprt',id);
         this.EditItemsid=id;
         this.editcategName=cateName;
         this.edititemname=itemName;
         this.editdescription=desc;

         this.Editimage='';
         this.Editpreview=this.getImage1(img);
        //  this.editdetails=details;
         this.editstatus=status;
         this.previ=img;
         this.editprice=price

       },
       editItems()
       {

         
           let payload={
           id:this.EditItemsid,
           img: this.mainimg2?this.mainimg2:this.previ,
          cateName:this.editcategName,
          itemName: this.edititemname,
          type: null,
          desc: this.editdescription,
          price: this.editprice,
          t1: null,
          t2: null,
          t3: null,
          t4: null,
          t5: "Available",
          write: this.editVolume,
          prio: this.editPriority,
          status: this.editstatus,
       }

       this.$store.dispatch('Updateitems',payload);

       
          Swal.fire({
           text: 'Successfully Update record',
           target: '#custom-target',
           customClass: {
             container: 'position-absolute'
           },
           toast: true,
           position: 'top'
       });

       document.getElementById('close1').click();

       this.close();
       document.getElementById('close').click();



       },
     async  fetchDeprt(){

         this.loader=true;
        await this.$store.dispatch('ItemList')
        await this.$store.dispatch('CategoryList')
         this.loader=false;
         //fetchDepart

         
      
       },
     async  submitForm(){

       

           let payload={
            img: this.mainimg,
           cateName:this.categName,
           itemName:this.itemname,
           type:null,
           desc:this.description,
           price:this.price,
           t1:this.t1,
           t2:this.t2,
           t3:this.t3,
           t4:this.t4,
           t5:this.t5,
           write:this.Volume,
           prio:this.Priority,
           status:this.status,

       }

       await this.$store.dispatch('addItems',payload);


           // let payload={
           //     image:this.img,
           //     status:this.status,
           //     details:this.details
           // }
           // this.tableData= this.$store.dispatch('AddBanner',payload);

           this.departmentname='';
           document.getElementById('close').click();

           this.close();
           document.getElementById('close').click();

       },
    
     }
  
 }
 </script>

<template>
  <div>

     <div class="loading" v-if="loader">
        <div class="contented" style="margin-left: -102px;margin-top: -133px;">
          <div class="circle"></div>
          <div class="circle"></div>
          <!-- <div class="circle"></div>
          <div class="circle"></div> -->
        </div>
      </div>
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1 class="m-0">Banner List</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item active">Banner List</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- Main content -->
    <section class="content">
      <div class="container-fluid">
        <!-- SELECT2 EXAMPLE -->
     
        <!-- /.card -->
        <div class="card row">
          <div class="card-header">
            <h3 class="card-title">Banner List</h3>

            <div class="card-tools">
              <button type="button" class="btn btn-success" data-toggle="modal" data-target="#modal-default-addDepart">Add Banner</button>
              <!-- <button type="button" class="btn btn-tool" data-card-widget="collapse">
                <i class="fas fa-minus"></i>
              </button> -->
              <!-- <router-link class="btn btn-success" to="/addBanner"> <i class="nav-icon fas fa-plus bgclored"></i>
                Add Banner
              </router-link> -->

            </div>
          </div>
          <!-- /.card-header -->
            <div class="">
              <table id="tableComponent" class="table table-bordered table-striped text-center">
                  <thead>
                  <tr>
                    <th>ID</th>
                    <th>Image</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(slide,index) in fetchBanner" :key="index">
                        <td>{{index+1}}</td>
                        <td class="text-center">
                            <img :src="getImage1(slide.img)"  class="img-fluid w-100 peinture-ombre-interne-fine" alt="..."  style="height:100px;max-width: 30%;">

                        </td>
                        <td>
                            <p v-if="slide.status === 'Hide'">False</p>
                            <p v-if="slide.status != 'Hide'">True</p>
                        </td>
                        <td class="text-center">
                            <button type="button"  data-toggle="modal" data-target="#modal-default" @click="editBanner(slide.id,slide.img,slide.details,slide.status)" style="border: none;color: #28a745;background: none;"><i class="fa fa-pencil-square fa-2x" aria-hidden="true"></i></button>
                            <button type="button"  @click="deleteBanner(slide.id)" style="border: none;color: #28a745;background: none;"><i class="fa fa-trash fa-2x" aria-hidden="true"></i></button>
                        </td>
                    </tr>

                  </tbody>
                
                </table>
             
            </div>
            <!-- /.row -->

          <!-- /.card-body -->
          <div class="card-footer">
          
          </div>
        </div>
     </div>
    </section> 
    <div class="modal fade" id="modal-default-addDepart">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Add Banner</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form v-on:submit.prevent="submitForm"  ref="textareaform" enctype="multipart/form-data">

                <div class="card-body">
                  <div class="form-group">
                  <label for="exampleInputEmail1">Image</label>
                  <image-uploader
                      :preview="true"
                      :maxWidth="512"
                      :maxHeight="512"
                      :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                      :capture="false"
                      :debug="1"
                      doNotResize="gif"
                      :autoRotate="true"
                      outputFormat="verbose"
                      @input="setImage"
                    > </image-uploader>
                </div>
              
                  <div class="form-group">
                    <label for="exampleInputEmail1">Details</label>
                    <input type="text" v-model="details"  class="form-control" id="details" placeholder="Enter details">
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Status</label>
                    <select v-model="status"  class="form-control" id="status" required>
                        <option value="true">True</option>
                        <option value="Hide">false</option>
                    </select>
                    <!-- <input type="text" v-model="status"  class="form-control" id="status" placeholder="Enter status"> -->
                  </div>
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
              <button type="button" id="close" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>

    <div class="modal fade" id="modal-default" @close="showModal = false">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title">Edit Banner</h4>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="editbannerses" enctype="multipart/form-data">

                <div class="card-body">
                  <img :src="Editpreview" width="100" height="100" alt="" srcset="">
                  <div class="form-group">
                  <label for="exampleInputEmail1">Image</label>
                  <image-uploader
                      :preview="true"
                      :maxWidth="512"
                      :maxHeight="512"
                      :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                      :capture="false"
                      :debug="1"
                      doNotResize="gif"
                      :autoRotate="true"
                      outputFormat="verbose"
                      @input="setImage2"
                    > </image-uploader>
                </div>
                    
                  <div class="form-group">
                    <label for="exampleInputEmail1">Details</label>
                    <input type="text" v-model="editdetails"  class="form-control" id="details" placeholder="Enter details">
                  </div>
                  <div class="form-group">
                    <label for="exampleInputEmail1">Status</label>
                    <select v-model="editstatus" class="form-control" id="editstatus">
                        <option value="true">True</option>
                        <option value="Hide">false</option>
                    </select>
                    <!-- <input type="text" v-model="editstatus"  class="form-control" id="status" placeholder="Enter status"> -->
                  </div>
                  
              
                </div>
                <!-- /.card-body -->

                <div class="card-footer">
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>

              </form>
            </div>
            <div class="modal-footer justify-content-between">
             
              <button type="button" id="close1" class="btn btn-default" data-dismiss="modal"  @click="close">Close</button>

            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
    </div>
      <!-- /.modal -->   
  </div>

</template>

<script>
 import { mapGetters } from "vuex"
 import ImageUploader from "../components/ImageUploader.vue";
 import Swal from 'sweetalert2'

  export default {
    name:'hello-world',
    components: {
    ImageUploader,
  },
      data() {
          return {
            departmentname:'',
            Editdepartmentname:'',
            EditdepartmentID:'',
            status:'',
            image:'',
            editimage:'',
            base64:'',
            columns: ['id', 'name'],
            tableData: [],
            details:'',
            editdetails:'',
            editstatus:'',
            Editpreview:'',
            Editbannerid:'',
            previ:'',
            loader:false,
            hasImage: false,
            mainimg:'',
            mainimg2:''
          }
      },
      computed: {
    ...mapGetters(['fetchBanner'])
      },
      mounted() {
        this.fetchDeprt()
      },
      methods: {
        deleteBanner(id){
          Swal.fire({
            title: 'Do you want to Delete this Banner?',
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: 'Delete',
            denyButtonText: `Don't Delete`,
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                    this.$store.dispatch('DeleteBanner',id);

                 this.$swal('Delete!', '', 'success')
            } else if (result.isDenied) {
                 this.$swal('Changes are not saved', '', 'info')
            }
            })
        },
        selectImage () {
          this.$refs.fileInput.click()
      },
      
      getImage1(imagePath) {
          return 'https://wdlapi.areteonline.in/Img/Bann/'+imagePath;
      },
        close() {
        this.$emit('close');
      },
      async  editBanner(id,img,details,status)
        {
        // await this.$store.dispatch('getRecordDeprt',id);
          this.Editbannerid=id;
          this.Editimage='';
          this.Editpreview=this.getImage1(img);
          this.editdetails=details;
          this.editstatus=status;
          this.previ=img;

        },
        editbannerses()
        {

          
            let payload={
            id:this.Editbannerid,
            img: this.mainimg2?this.mainimg2:this.previ,
            status:this.editstatus,
            details:this.editdetails
        }

        this.$store.dispatch('UpdateBanners',payload);

        
        Swal.fire({
            text: 'Successfully Update record',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });

        document.getElementById('close1').click();

        this.close();
        document.getElementById('close').click();



        },
      async  fetchDeprt(){

          this.loader=true;
         await this.$store.dispatch('BannerList')
          this.loader=false;
          //fetchDepart

          
       
        },
    setImage: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log(output);

      let image_file = this.urlToFile(output.dataUrl);
      //this.submitdt(image_file);
      this.mainimg=image_file;
    },
    setImage2: function (output) {
      this.hasImage = true;
      this.image = output;
      console.log(output);

      let image_file = this.urlToFile(output.dataUrl);
      //this.submitdt(image_file);
      this.mainimg2=image_file;
    },
    urlToFile(url) {
      let arr = url.split(",");
      console.log(arr);
      let mime = arr[0].match(/:(.*?);/)[1];
      let data = arr[1];

      let dataStr = atob(data);
      let n = dataStr.length;
      let dataArr = new Uint8Array(n);

      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n);
      }

      let file = new File([dataArr], "File.jpg", { type: mime });

      return file;
    },
      async  submitForm(){

        this.loader=true;

            let payload={
            img: this.mainimg,
            status:this.status,
            details:this.details
        }

        await this.$store.dispatch('addBanner',payload);

        this.loader=false;
            // let payload={
            //     image:this.img,
            //     status:this.status,
            //     details:this.details
            // }
            // this.tableData= this.$store.dispatch('AddBanner',payload);

            this.departmentname='';
            document.getElementById('close').click();

            this.close();
            document.getElementById('close').click();

        },
     
      }
   
  }
  </script>
<style  >
.VuePagination {
  text-align: center;
}

.vue-title {
  text-align: center;
  margin-bottom: 10px;
}

.vue-pagination-ad {
  text-align: center;
}

.glyphicon.glyphicon-eye-open {
  width: 16px;
  display: block;
  margin: 0 auto;
}

th:nth-child(3) {
  text-align: center;
}

.VueTables__child-row-toggler {
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: block;
  margin: auto;
  text-align: center;
}

.VueTables__child-row-toggler--closed::before {
  content: "+";
}

.VueTables__child-row-toggler--open::before {
  content: "-";
}

.contented {
	width: 50vmin;
	height: 50vmin;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: scale(0.65);
	animation: spin-all calc(var(--sp) * 2) linear 0s infinite
}

.circle {
	--in: 80%;
	--ar: #9c4a29;
	--dt: #fcd1ba;
	--shadow: drop-shadow(0vmin 0vmin 0.5vmin #000) drop-shadow(0vmin 1vmin 0.5vmin #0004);
	--cross: linear-gradient(0deg, #fff0 calc(50% - 2px), #000 calc(50% - 1px) calc(50% + 1px), #fff0 calc(50% + 2px)), linear-gradient(90deg, #fff0 calc(50% - 2px), #000 calc(50% - 1px) calc(50% + 1px), #fff0 calc(50% + 2px));
    border: 6vmin solid #9c4a29;
    width: var(--in);
    height: var(--in);
    border-radius: 100%;
    position: absolute;
    box-sizing: border-box;
    border-top-color: #fcd1ba;
    border-left-color: #9c4a29;
    top: 15vmin;
    right: -10vmin;
    animation: spin-bot var(--sp) ease 0s infinite;
    background-image: var(--cross), radial-gradient(var(--dt) 5.5vmin, #fff0 calc(5.5vmin + 1px));
	background-repeat: no-repeat;
    background-size: 3vmin 1vmin, 1vmin 3vmin, 100% 100%;
    background-position: center center;
	filter: var(--shadow);
}

.circle:nth-child(2) {
    --in: 60%;
    top: -2vmin;
    animation: spin-top var(--sp) ease 0s infinite;
    transform: rotate(-45deg);
    background-image: var(--cross), radial-gradient(var(--dt) 1.25vmin, #fff0 calc(1.25vmin + 1px));
    right: -4vmin;
	filter: hue-rotate(10deg) var(--shadow);
    background-size: 1.4vmin 1vmin, 1vmin 1.4vmin, 100% 100%;
}

.circle:nth-child(3) {
    --in: 100%;
    top: -5vmin;
    left: -13vmin;
    transform: rotate(175deg);
    animation: spin-left var(--sp) ease calc(var(--sp) / 4) infinite;
    background-image: var(--cross), radial-gradient(var(--dt) 9vmin, #fff0 calc(9vmin + 1px));
	filter: hue-rotate(20deg) var(--shadow);
    background-size: 5vmin 1vmin, 1vmin 5vmin, 100% 100%;
}

.circle:nth-child(4) {
    --in: 60%;
    top: 35vmin;
    left: -6vmin;
    transform: rotate(-280deg);
    animation: spin-last var(--sp) ease calc(calc(calc(var(--sp) / 4) + var(--sp)) * -1) infinite;
    background-image: var(--cross), radial-gradient(var(--dt) 2.5vmin, #fff0 calc(2.5vmin + 1px));
	filter: hue-rotate(30deg) var(--shadow);
    background-size: 2vmin 1vmin, 1vmin 2vmin, 100% 100%;
}


@keyframes spin-all {
	0% { transform: rotate(0deg) scale(0.65); }
	100% { transform: rotate(360deg) scale(0.65); }
}


@keyframes spin-top {
	0% { transform: rotate(-45deg); }
	100% { transform: rotate(315deg); }
}

@keyframes spin-bot {
	0%, 35% { transform: rotate(0deg); }
	80%, 100% { transform: rotate(-360deg); }
}

@keyframes spin-left {
	0%, 35% { transform: rotate(175deg); }
	80%, 100% { transform: rotate(535deg); }
}
:root {
	--sp: 2.5s; /* change speed */
}	
@keyframes spin-last {
	0%, 10% { transform: rotate(-280deg); }
	90%, 100% { transform: rotate(-640deg); }
}
/* loader */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
    background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));

  background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}
</style>

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Vue3Storage from "vue3-storage";

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false
import "admin-lte/dist/js/adminlte.min.js"
import 'admin-lte/dist/css/adminlte.min.css'

new Vue({
  router,
  VueSweetalert2,
  store,
  Vue3Storage,
  render: h => h(App),
  created() {
    this.$store.dispatch('loadStoredState')
   // this.$store.dispatch('loadUser')
  },
}).$mount('#app')
<template>

  <div id="nav" v-if="this.$store.state.isLoggedIn">
    <body class="hold-transition sidebar-mini layout-fixed sidebar-closed">
<div class="wrapper">

  <!-- Preloader -->
  <!-- <div class="preloader flex-column justify-content-center align-items-center">
    <img class="animation__shake" src="dist/img/AdminLTELogo.png" alt="AdminLTELogo" height="60" width="60">
  </div> -->

  

  <!-- Navbar -->
  <nav class="main-header navbar navbar-expand navbar-white navbar-light">
    <!-- Left navbar links -->
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link" data-widget="pushmenu" href="#" role="button"><i class="fas fa-bars"></i></a>
      </li>
    
    </ul>

    <!-- Right navbar links -->
    <ul class="navbar-nav ml-auto">
      <!-- Messages Dropdown Menu -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" title="Inactive User List" data-toggle="dropdown" href="#" @click="checkuser">
          <i class="far fa-comments"></i>
          <span class="badge badge-danger navbar-badge"></span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <div class="dropdown-item" v-for="value in fetchData.NotActiveUser" :key="value">
             <div class="media" action="">
              <img src="img/user3-128x128.jpg" alt="User Avatar" class="img-size-50 img-circle mr-3">
              <div class="media-body">
                <h3 class="dropdown-item-title">
                  {{value.name}}
                  <span class="float-right text-sm text-warning"><button class="btn btn-success"
                     @click="activeUser(value.userId,value.name,value.email,value.mobile,value.department,value.usertype,value.password)">Active</button></span>
                </h3>
                <p class="text-sm">{{value.email}}</p>
                <p class="text-sm text-muted"><i class="far fa-clock mr-1"></i> {{value.duedate}}</p>
              </div>
            </div>
          </div>

                 <div class="dropdown-divider"></div>
                 

        </div>
      </li> -->
      <!-- Notifications Dropdown Menu -->
      <!-- <li class="nav-item dropdown">
        <a class="nav-link" data-toggle="dropdown" href="#">
          <i class="far fa-bell"></i>
          <span class="badge badge-warning navbar-badge">15</span>
        </a>
        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right">
          <span class="dropdown-item dropdown-header">15 Notifications</span>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-envelope mr-2"></i> 4 new messages
            <span class="float-right text-muted text-sm">3 mins</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-users mr-2"></i> 8 friend requests
            <span class="float-right text-muted text-sm">12 hours</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item">
            <i class="fas fa-file mr-2"></i> 3 new reports
            <span class="float-right text-muted text-sm">2 days</span>
          </a>
          <div class="dropdown-divider"></div>
          <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a>
        </div>
      </li> -->
      <li class="nav-item">
        <a class="nav-link" data-widget="fullscreen" href="#" role="button">
          <i class="fas fa-expand-arrows-alt"></i>
        </a>
      </li>
      <!-- <li class="nav-item">
        <a class="nav-link" data-widget="control-sidebar" data-controlsidebar-slide="true" href="#" role="button">
          <i class="fas fa-th-large"></i>
        </a>
      </li> -->
    </ul>
  </nav>
  <!-- /.navbar -->

  <!-- Main Sidebar Container -->
  <aside class="main-sidebar sidebar-dark-primary elevation-4" style="background:#fdd4bc">
    <!-- Brand Logo -->
    <a  class="brand-link">
      <img src="img/logo1.png" alt="AdminLTE Logo" class="brand-image  elevation-3">
      <span class="brand-text font-weight-light" style="color: white;">Woodlands Admin</span>
    </a>

    <!-- Sidebar -->
    <div class="sidebar">
      <!-- Sidebar user panel (optional) -->
      <div class="user-panel mt-3 pb-3 mb-3 d-flex">
        <div class="image">
          <span class="text-uppercase" style="color:white">{{username}}</span>
        </div>
        <div class="info">
          <a href="#" class="d-block"></a>
        </div>
      </div>

      <!-- Sidebar Menu -->
      <nav class="mt-2">
        <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
          <!-- Add icons to the links using the .nav-icon class
            with font-awesome or any other icon font library -->

            <div v-if="this.$store.state.isUserIn==true">
              <!-- <li class="nav-item">
                <router-link class="nav-link " to="/dashboard"> <i class="nav-icon fas fa-tachometer-alt bgclored"></i>
                  <p class="bgclored">
                    Dashboard
                  </p>
                </router-link>
                
              </li> -->
              <!-- <li class="nav-item">
                <router-link class="nav-link " to="/full-calendar"> <i class="nav-icon fas fa-tachometer-alt"></i>
                  <p>
                    Calendar
                  </p>
                </router-link>
                
              </li> -->
              <li class="nav-item">
              <router-link class="nav-link " to="/banners"> <i class="nav-icon fas fa-tree bgclored"></i>
              <p class="bgclored">
                Banner
              </p>
              </router-link>
              </li>

              <li class="nav-item">
                <router-link class="nav-link " to="/category"> <i class="nav-icon far fa-plus-square bgclored"></i>
                  <p class="bgclored">
                    Category
                  </p>
                </router-link>
              </li>
              <li class="nav-item">
                  <router-link class="nav-link " to="/item"> <i class="nav-icon fas fa-tree bgclored"></i>
                    <p class="bgclored">
                      Item
                    </p>
                  </router-link>
                
                </li>
                <li class="nav-item">
                  <router-link class="nav-link " to="/Customers"> <i class="nav-icon fas fa-tree bgclored"></i>
                    <p class="bgclored">
                      Guest
                    </p>
                  </router-link>
                
                </li>
            </div>

         
                   
          <li class="nav-item">
            <button class="nav-link" title="Logout" @click="logout()" style="background: wheat;"><i class='fas fa-sign-out-alt' style='font-size:20px;color:red'></i>
            </button>
          </li>
          <br/>
          <!-- <li class="nav-item">
          <div class="teams-share-button" data-href="" data-button-type="medium" data-icon-px-size="160" data-preview="true"></div>
        </li> -->
          
        </ul>
      </nav>
      <!-- /.sidebar-menu -->
    </div>
    <!-- /.sidebar -->
  </aside>

  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    
    <!-- /.content-header -->

    <!-- Main content -->
    <!-- <section class="content">
      <div class="container-fluid">
       
      
        <div class="row"> -->
         <!-- main section start -->
         <router-view />

    
        <!-- </div>
      </div>
    </section> -->
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
  <footer class="main-footer">
    <strong>Copyright &copy; 1990-2022 <a href="https://areteservices.in">Areteservices.in</a>.</strong>
    All rights reserved.
    <div class="float-right d-none d-sm-inline-block">
      <b>Version</b> 3.2.0
    </div>
  </footer>

  <!-- Control Sidebar -->
  <aside class="control-sidebar control-sidebar-dark">
    <!-- Control sidebar content goes here -->
  </aside>
  <!-- /.control-sidebar -->
</div>
<!-- ./wrapper -->


</body>
   

  </div>
  <div id="nav" v-else>
    <!-- <button @click="showAlert">Hello world</button> -->

    <router-view />

  </div>

</template>

<script>

import { mapGetters } from "vuex"
import update from './mixins/update'

  export default {
      name: 'App',
      data() {
        return {
          checklogin:this.$store.state.isLoggedIn,
          sessioncheckdata:'',
          usersdata:this.$store.state.NotActiveUser,
          role:this.$store.state.Role,
          username:localStorage.getItem('Username'),
        }
      },
      mixins: [update],
      components:{
      }, 
      updated(){
        this.username=localStorage.getItem('Username');
      }, 
      mounted(){
      this.userdepartment()
      this.checkUpdate()

      },
      methods: {
        checkUpdate(){
            // if(this.updateExists){

            //   this.$swal({
            //     title: 'An update is available',
            //     showDenyButton: true,
            //     showCancelButton: true,
            //     confirmButtonText: 'Update',
            //     denyButtonText: `Don't save`,
            //   }).then((result) => {
            //     /* Read more about isConfirmed, isDenied below */
            //     if (result.isConfirmed) {
            //         this.refreshApp()
            //     } else if (result.isDenied) {
            //       this.$swal('Changes are not saved', '', 'info')
            //     }
            //   })

            // }
        },  
        // refreshApp() {
        //     this.updateExists = false
        //     // Make sure we only send a 'skip waiting' message if the SW is waiting
        //     if (!this.registration || !this.registration.waiting) return
        //     // Send message to SW to skip the waiting and activate the new SW
        //     this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        //   },
     
      
        logout(){
          this.$store.dispatch('logout');
          if(this.$store.state.isLoggedIn==false)
          {
            this.$router.push('/');

          }
          
        },
        userdepartment(){
          
          // let dep=localStorage.getItem('department');
          // this.$store.dispatch('GetNotActiveUserList',dep);
           this.username=localStorage.getItem('Username');

        },
    showAlert() {
      // Use sweetalert2
      // this.$toast.show(`Hey! I'm here`);
          this.$swal({
            text: 'Toast with custom target',
            target: '#custom-target',
            customClass: {
              container: 'position-absolute'
            },
            toast: true,
            position: 'top'
        });
    },
  },
      computed: {
        ...mapGetters(['fetchData']),
  },

  }
</script>

<style scoped>
  #app {
      font-family: Avenir, Helvetica, Arial, sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-align: center;
      color: #2c3e50;
      margin-top: 60px;
  }

  body {
      background-color: #e9ecef;
  }
  .bgclored{
    color: white;
  }
</style>

<template>
  <div>

    <div class="loading" v-if="loader">
     <div class="contented" style="margin-left: -102px;margin-top: -133px;">
       <div class="circle"></div>
       <div class="circle"></div>
       <!-- <div class="circle"></div>
       <div class="circle"></div> -->
     </div>
 </div>
   <div class="content-header">
    
     <div class="container-fluid">
       <div class="row mb-2">
         <div class="col-sm-6">
           <h1 class="m-0">Guest List</h1>
         </div><!-- /.col -->
         <div class="col-sm-6">
           <ol class="breadcrumb float-sm-right">
             <li class="breadcrumb-item"><a href="#">Home</a></li>
             <li class="breadcrumb-item active">Guest List</li>
           </ol>
         </div><!-- /.col -->
       </div><!-- /.row -->
     </div><!-- /.container-fluid -->
   </div>
   <!-- Main content -->
   <section class="content">
     <div class="container-fluid">
       <!-- SELECT2 EXAMPLE -->
    
       <!-- /.card -->
       <div class="card row" style="overflow: scroll;">
         <div class="card-header">
           <h3 class="card-title">Guest List</h3>
            <p class="text-center">Total Unique Guest : {{fetchcustomer.length}}</p>
          
         </div>
         <!-- /.card-header -->
           <div class="">
             <table id="tableComponent" class="table table-bordered table-striped text-center">
                 <thead>
                 <tr>
                   <th>ID</th>
                   <th>Name</th>
                   <th>Mobile</th>
                   <th>Date</th>
                 </tr>
                 </thead>
                 <tbody>
                   <tr v-for="(slide,index) in fetchcustomer" :key="index">
                       <td>{{index+1}}</td>
                       <td>{{slide.name}}</td>
                       <td>{{slide.mobile}}</td>
                       <td>{{moment(slide.datetime)}}</td> 
                   </tr>

                 </tbody>
               
               </table>
            
           </div>
           <!-- /.row -->

         <!-- /.card-body -->
         <div class="card-footer">
         
         </div>
       </div>
    </div>
   </section> 
  </div>
 
</template>

<script>
import { mapGetters } from "vuex"
import moment from 'moment'

 export default {
   name:'hello-wordl',
     data() {
         return {
           departmentname:'',
           Editdepartmentname:'',
           EditdepartmentID:'',
           status:'',
           image:'',
           editimage:'',
           base64:'',
           columns: ['id', 'name'],
           tableData: [],
           details:'',
           editdetails:'',
           editstatus:'',
           Editpreview:'',
           Editbannerid:'',
           previ:'',
           loader:false,
         }
     },
     computed: {
   ...mapGetters(['fetchcustomer'])
     },
     mounted() {
       this.fetchDeprt()
     },
     methods: {
        moment: function (date) {
        if(date){
          return moment(date).format('DD-MM-Y');
        }
    },
     async  fetchDeprt(){

         this.loader=true;
        await this.$store.dispatch('CustomerList')
         this.loader=false;
         //fetchDepart

       },
       
     }
  
 }
 </script>

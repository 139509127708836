import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { isLoggedIn, logOut, logIn, UserMenu,isUserIn } from '../shared/utils/auth'

Vue.use(Vuex)
Vue.use(axios)

export default new Vuex.Store({
  state: {
      base_url:'https://tmsapipost.areteonline.in/',
      managerMenu:[],
      MainLogin:'',
        isLoggedIn: false,
        LoginUser:{},
        Users:[],
        isUserIn:false,
        banners:[], 
        onebanner:[],  
        Category:[],   
        categorydetails:[],    
        Items:[],
        Itemdetails:[],
        CustomerList:[],

  },
  getters: {
    fetchData(state){
      return state;
  },
  fetchUsers(state){
    return state.Users;
  },
  fetchBanner(state){
      return state.banners;
  },
  fetchCategory(state){
    return state.Category;
  },
  fetchItem(state){
    return state.Items;
  },
  fetchcustomer(state){
  return state.CustomerList;
 }
  },
  mutations: {
    setUser(state, payload) {
      state.user = payload
    },
  setLoggedIn(state, payload) {
      state.isLoggedIn = payload
    },
  increment(state){
      state.count++;
  },
  updatelogindata(state,payload){
      state.LoginUser=payload;
  },
  setUserIn(state, payload) {
      state.isUserIn = payload
    },
  updateBanners(state,payload){
      state.banners=payload;
    },
    uploadbanner(state,payload){
      state.onebanner=payload;
    },
    updateCategory(state,payload){
      state.Category=payload;
    },
    uploadcategory(state,payload){
      state.categorydetails=payload;
    },
    updateItems(state,payload){
      state.Items=payload;
    },
    uploaditemsde(state,payload){
      state.Itemdetails=payload;
    },
    updateCustomer(state,payload){
      state.CustomerList=payload;
    }
  },
  actions: {
    menuAction({commit}){
      let role=localStorage.getItem('Role');

      if(role=='manager'){
        let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
        commit('setmenu',payload);
      }else if(role=='User'){
        let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
        commit('setmenu',payload);
      }else if(role=='Administrator'){
        let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
        commit('setmenu',payload);
      }else if(role=='Director'){
        let payload=[{route:'dashboard'},{route:'users'},{route:'users'},]
        commit('setmenu',payload);
      }

    },
      loadStoredState(context) {
          context.commit('setLoggedIn', isLoggedIn())
         // context.commit('setManagerIn', isManagerIn())
          context.commit('setUserIn', isUserIn())
          //context.commit('setDirectorIn', isDirectoreIn())
          // context.commit('setUsername')
        },
        login({ dispatch },payload) {
          // simulate login
          dispatch('loadUser',payload)
        },
        logout({ commit }) {
          localStorage.setItem("userID",null);
          localStorage.setItem("Role",'');
          commit('setUser', {})
          commit('setLoggedIn', false)
          logOut()
        },
        async loadUser({ commit, dispatch },payload) {
          console.log('------loaduser----');
          if (!isLoggedIn()) {
            try {
                var optionAxios = {
                headers: {
                            'Content-Type': 'multipart/form-data',
                        }   
                      }
            
           // Make a request for a user with a given ID
            await  axios.get(`https://wdlapi.areteonline.in/api/logins/${payload.username},${payload.password}`,optionAxios)
                    .then(function (res) {
                        if(res.data.status==='true' || res.data.status==='True' || res.data.status==true){
                          
                          commit('setUser', res.data)
                          // localStorage.setItem("Role", res.data.data.userType);
                          localStorage.setItem("Role",'user');

                        
                            // }
                            //else if(res.data.data.userType=="User"){

                              UserMenu()
                              // commit('setMainLogin', res.data.data.name)
                              commit('setUserIn', true)
                              logIn()
                              commit('setLoggedIn', true)
                            //   localStorage.setItem("userID", res.data.data.userId);
                            //  localStorage.setItem("department", res.data.data.department);

                         
                         
                          
                        }else
                        {
                          dispatch('logout')
                        }
                    })
                    .catch(function (error) {
                        // handle error
                        console.log(error);
                    })
                    .then(function () {
                        // always executed
                        console.log('---------')
                    });

            
            } catch (error) {
              dispatch('logout')
            }
          }
        },
     async logincheck({commit},payload){
          const response = await axios.get(`https://jsonplaceholder.typicode.com/posts/${payload}`)
          const data = response.data;
          // console.log(data,'----------------test');
          commit('updatelogindata',data);
      },
      async BannerList({commit}){
        try{
          const response = await axios.get(`https://wdlapi.areteonline.in/api/banners/getall`)
          const data = response.data;
          // console.log(data,'----------------test');
          commit('updateBanners',data);
      } catch (err) {
        let data=[];
        commit('updateBanners',data);
      }

         
      },
      async addBanner({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://wdlapi.areteonline.in/api/Banners/`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploadbanner',res);
                  dispatch('BannerList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
                
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async UpdateBanners({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://wdlapi.areteonline.in/api/Banners/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploadbanner',res);
                  dispatch('BannerList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async DeleteBanner({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.delete(`https://wdlapi.areteonline.in/api/Banners/${payload}`,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploadbanner',res);
                 dispatch('BannerList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async CategoryList({commit}){
        try{
          const response = await axios.get(`https://wdlapi.areteonline.in/api/categs/all`)
          const data = response.data;
          // console.log(data,'----------------test');
          commit('updateCategory',data);
      } catch (err) {
        let data=[];
        commit('updateCategory',data);
      }
      
      },
      async addCategory({commit,dispatch},payload){
       
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://wdlapi.areteonline.in/api/categs`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploadcategory',res);
                 dispatch('CategoryList');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async UpdateCategoryDetails({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://wdlapi.areteonline.in/api/categs/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploadcategory',res);
                  dispatch('CategoryList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async Deletecategory({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.delete(`https://wdlapi.areteonline.in/api/categs/${payload}`,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploadcategory',res);
                 dispatch('CategoryList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async ItemList({commit}){
        try{
          const response = await axios.get(`https://wdlapi.areteonline.in/api/items/getall`)
          const data = response.data;
          // console.log(data,'----------------test');
          commit('updateItems',data);

      } catch (err) {
        // Handle error
        // console.log(err);
        let data=[];
        commit('updateItems',data);

    }

     
      },
      async addItems({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.post(`https://wdlapi.areteonline.in/api/Items`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploaditemsde',res);
                 dispatch('ItemList');
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async DeleteItems({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }                         }
          await  axios.delete(`https://wdlapi.areteonline.in/api/Items/${payload}`,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploaditemsde',res);
                 dispatch('ItemList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
      async Updateitems({commit,dispatch},payload){
        var optionAxios = {
          headers: {
                          'Content-Type': 'multipart/form-data'
                  }   }
          await  axios.post(`https://wdlapi.areteonline.in/api/Items/${payload.id}`,payload,optionAxios)
              .then(function (res) {
                  // handle success
                 commit('uploaditemsde',res);
                  dispatch('ItemList');
                 // dispatch('getCheckListdetailsUser',payload.tasklistid);
              })
              .catch(function (error) {
                  // handle error
                  console.log(error);
              })
              .then(function () {
                  // always executed
                  console.log('--------s-')
              });

      },
     async CustomerList({commit}){
          try{
            const response = await axios.get(`https://wdlapi.areteonline.in/api/customers`)
            const data = response.data;
            // console.log(data,'----------------test');
            commit('updateCustomer',data);
        } catch (err) {
          let data=[];
          commit('updateCustomer',data);
        }
     }
     
  },
  modules: {
  }
})

import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import woodlandsLogin from "../views/woodlandsLogin.vue";
import Banners from "../views/Banners.vue";

import Category from "../views/category.vue";

import item from "../views/item.vue";
import Customers from "../views/Customers.vue";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: woodlandsLogin
  },
  {
    path: "/banners",
    name: "banners",
    component: Banners,
    beforeEnter (to, from, next) {
      if (localStorage.getItem("isLoggedIn")==='true') {
        next()
      } else {
        next('/')
      }
    },
},
{
  path: "/category",
  name: "category",
  component: Category,
  beforeEnter (to, from, next) {
    if (localStorage.getItem("isLoggedIn")==='true') {
      next()
    } else {
      next('/')
    }
  },
},
{
  path: "/item",
  name: "item",
  component: item,
  beforeEnter (to, from, next) {
    if (localStorage.getItem("isLoggedIn")==='true') {
      next()
    } else {
      next('/')
    }
  },
},
{
  path: "/Customers",
  name: "Customers",
  component: Customers,
  beforeEnter (to, from, next) {
    if (localStorage.getItem("isLoggedIn")==='true') {
      next()
    } else {
      next('/')
    }
  },
}
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes
})

export default router

export function isLoggedIn() {
    return localStorage.getItem("isLoggedIn") == 'true';
  }
  
  export function logIn() {
    localStorage.setItem("isLoggedIn", true);
  }
  
  export function logOut() {
    localStorage.setItem("isLoggedIn", false);    
    localStorage.setItem("User",false);
  }

  export function UserMenu(){
    localStorage.setItem("User",true);
  }

  export function isUserIn() {
    return localStorage.getItem("User") == 'true';
  }

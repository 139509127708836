<template>
    <section id="accueil">
      <div class="triangle_rose"></div>
     
     
        <body class="hold-transition login-page">
          <div class="login-box">
            <div class="login-logo">
              <!-- <p style="color:white"><b>Woodlands - Admin</b></p> -->
            </div>
            <!-- /.login-logo -->
            <div class="card">
              <div class="card-body login-card-body">
                  <img src="img/loginlogo.png" class="img-fluid" alt="" srcset="">
                  <!-- <p class="login-box-msg">Login</p> -->
  
                <form  @submit.prevent="checkLogin">
                  <div class="input-group mb-3">
                    <input
                      type="text"
                      name="name"
                      class="form-control"
                      placeholder="Name"
                      v-model="name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-envelope"></span>
                      </div>
                    </div>
                  </div>
                  <div class="input-group mb-3">
                    <input
                      name="password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      v-model="password"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">
                        <span class="fas fa-lock"></span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <div class="icheck-primary">
                        <input type="checkbox" id="remember" />
                        <label for="remember"> Remember Me </label>
                      </div>
                    </div>
                    <!-- /.col -->
                    <div class="col-4">
                      <button type="submit" class="btn btn-primary btn-block">
                        Sign In
                      </button>
                      
                    </div>
                    <!-- /.col -->
                  </div>
                </form>
                <div class="social-auth-links text-center mb-3">
                   
                </div>
  
              
                
              </div>
              <!-- /.login-card-body -->
            </div>
          </div>
          <!-- /.login-box -->
        </body>
        
      <div class="triangle_vert"></div>
  
     
    </section>
  </template>
  
  <script>
  import { mapGetters } from "vuex"
  import Swal from 'sweetalert2'
    // window.Swal = swal;
  export default {
      name:"TMSLogin",
      data() {
              return {
                  // for new tasks
                  loginCheck: false,
                  name: '',
                  password:'',
              };
          },
          computed: {
      ...mapGetters(['fetchData'])
        },
        mounted() {
        this.mountedFun();
          console.log('mounted!')
        },  
    methods: {
      mountedFun(){
        let check= localStorage.getItem("isLoggedIn");
             // alert(check)
             setTimeout(() => {
              if(check==='true'){
  
                this.$router.push('banners');
               
               
              }else
              {
                this.$router.push('/');
              }
             }, 400);
            
      },
      
     async checkLogin()
      { 
        let payload={
          username:this.name,
          password:this.password
        }
  
           await this.$store.dispatch('loadUser',payload);
            // await this.$store.dispatch('logincheck',1);
            // alert(this.$store.state.LoginCheck);   
            // this.$storage.setStorageSync("test-key", "testdata");
            
            setTimeout(() => {
              let check= localStorage.getItem("isLoggedIn");
             // alert(check)
              if(check==='true'){
  
                this.$router.push('banners');
  
              
              }else
              {
                console.log('error');
                Swal.fire({
                      text: 'Username and Password wrong.',
                      target: '#custom-target',
                      customClass: {
                        container: 'position-absolute'
                      },
                      toast: true,
                      position: 'top'
                  });
  
                this.$router.push('/');
              }
              
            }, 400);
        
      }
  }
  
  };
  </script>
  <style scoped>
  body {
    padding: 0;
    margin: 0;
  }
  
  section#accueil {
    background: #ecf6f8;
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
  }
  
  
  .login-page{
    background-color: #fcd1ba !important;
  }
  
  </style>
  